
import { computed, defineComponent, onMounted, ref } from "vue";
import Step1 from "@/views/auth/signUpAdmin/steps/Step1.vue";
import Step2 from "@/views/auth/signUpAdmin/steps/Step2.vue";
import Step3 from "@/views/auth/signUpAdmin/steps/Step3.vue";
import Step4 from "@/views/auth/signUpAdmin/steps/Step4.vue";
import Step5 from "@/views/auth/signUpAdmin/steps/Step5.vue";
import { StepperComponent } from "@/assets/ts/components";
import * as Yup from "yup";
import { useForm } from "vee-validate";
import { setSubscribeAdmin } from "@/core/helpers/user";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import NotifService from "@/core/services/NotifService";
import useError from "@/core/composable/useError";
import useTranslate from "@/core/composable/useTranslate";

interface IStep1 {
  name: string;
  surname: string;
  email: string;
  password: string;
  cpassword: string;
  phoneNumber: string;
  accountName: string;
}

interface IStep2 {
  accountTeamSize: string;
  accountPlan: string;
}

interface IStep3 {
  businessName: string;
  businessDescriptor: string;
  businessType: string;
  businessDescription: string;
  businessEmail: string;
}

interface IStep4 {
  nameOnCard: string;
  cardNumber: string;
  cardExpiryMonth: string;
  cardExpiryYear: string;
  cardCvv: string;
  saveCard: string;
}

interface CreateAccount extends IStep1, IStep2, IStep3, IStep4 {}

export default defineComponent({
  name: "kt-signup-admin",
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
  },
  setup() {
    const _stepperObj = ref<StepperComponent | null>(null);
    const signUpAdminRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);
    const { translate } = useTranslate();
    const store = useStore();
    const isSubscribed = ref(false);
    const router = useRouter();

    const formData = ref<CreateAccount>({
      name: "",
      surname: "",
      email: "",
      password: "",
      cpassword: "",
      phoneNumber: "",
      accountName: "",
      accountTeamSize: "",
      accountPlan: "1",
      businessName: "",
      businessDescriptor: "",
      businessType: "",
      businessDescription: "",
      businessEmail: "",
      nameOnCard: "",
      cardNumber: "",
      cardExpiryMonth: "",
      cardExpiryYear: "",
      cardCvv: "",
      saveCard: "",
    });

    onMounted(() => {
      _stepperObj.value = StepperComponent.createInsance(
        signUpAdminRef.value as HTMLElement
      );
    });
    const resultSubscribeUser = computed(() => {
      return store.getters.resultSubscribeUser;
    });

    const { getError } = useError();

    const createAccountSchema = [
      Yup.object({
        name: Yup.string()
          .required(` ${translate("firstNameRequired")}`)
          .label("First Name"),
        surname: Yup.string()
          .required(` ${translate("lastNameRequired")}`)
          .label("Second Name"),
        email: Yup.string()
          .min(4)
          .required(` ${translate("emailRequired")}`)
          .email()
          .label("Email"),
        password: Yup.string()
          .required("No password provided.")
          .min(8, "Password is too short - should be 8 chars minimum.")
          .matches(
            /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            ` ${translate("passwordRequired")}`
          )
          .label("Password"),
        cpassword: Yup.string()
          .min(8)
          .required()
          .oneOf(
            [Yup.ref("password"), null],
            ` ${translate("confirmPasswordRequired")}`
          )
          .label("Password Confirmation"),
        accountName: Yup.string()
          .required()
          .matches(/^[a-z0-9-]*$/, ` ${translate("accountNameRequired")}`)
          .label("Account Name"),
      }),
      Yup.object({
        accountTeamSize: Yup.string()
          .required(` ${translate("accountTeamSizeRequired")}`)
          .label("Specify Team Size"),
      }),
      Yup.object({
        businessName: Yup.string()
          .required(` ${translate("businessNameRequired")}`)
          .label("Business Name"),
        businessDescriptor: Yup.string()
          .required(` ${translate("businessDescriptorRequired")}`)
          .label("Shortened Descriptor"),
        businessDescription: Yup.string()
          .required(` ${translate("businessDescriptorRequired")}`)
          .label("Business Description"),
        businessType: Yup.string().required().label("Corporation Type"),
        businessEmail: Yup.string()
          .min(4)
          .required(` ${translate("businessEmailRequired")}`)
          .email()
          .label("Contact Email"),
      }),
      Yup.object({
        nameOnCard: Yup.string()
          .required(` ${translate("nameOnCardRequired")}`)
          .label("Name On Card"),
        cardNumber: Yup.string()
          .required(` ${translate("cardNumberRequired")}`)
          .label("Card Number"),
        cardExpiryMonth: Yup.string()
          .required(` ${translate("cardExpiryMonthRequired")}`)
          .label("Expiration Month"),
        cardExpiryYear: Yup.string()
          .required(` ${translate("cardExpiryYearRequired")}`)
          .label("Expiration Year"),
        cardCvv: Yup.number()
          .typeError(` ${translate("cardCvvRequired")}`)
          .nullable(true)
          .required()
          .positive()
          .integer()
          .label("CVV"),
      }),
    ];

    const currentSchema = computed(() => {
      return createAccountSchema[currentStepIndex.value];
    });

    const { resetForm, handleSubmit } = useForm<
      IStep1 | IStep2 | IStep3 | IStep4
    >({
      validationSchema: currentSchema,
    });

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }

      return _stepperObj.value.totatStepsNumber;
    });

    resetForm({
      values: {
        ...formData.value,
      },
    });

    const handleStep = handleSubmit((values) => {
      formData.value = {
        ...formData.value,
        ...values,
      };

      currentStepIndex.value++;

      if (!_stepperObj.value) {
        return;
      }
      _stepperObj.value.goNext();
    });

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value--;

      _stepperObj.value.goPrev();
    };
    const restStepIndex = () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value = 0;
      _stepperObj.value.goto(1);
    };
    const formSubmit = async () => {
      await setSubscribeAdmin(formData.value);
      if (getError.value.error === "Bad Request") {
        NotifService.displayErrorAlert(getError.value.message);
        restStepIndex();
      } else {
        if (!_stepperObj.value) {
          return;
        }
        isSubscribed.value = true;
        router.push({ name: "home" });
      }
    };

    return {
      signUpAdminRef,
      previousStep,
      handleStep,
      formSubmit,
      totalSteps,
      currentStepIndex,
      resultSubscribeUser,
      restStepIndex,
      isSubscribed,
    };
  },
});
