
import { defineComponent, ref } from "vue";
import { Field, ErrorMessage } from "vee-validate";

export default defineComponent({
  name: "step-2",
  components: {
    Field,
    ErrorMessage,
  },
  setup() {
    const isOnePerson = ref(false);
    const isSmallGroup = ref(false);
    const isMiddleGroup = ref(false);
    const isBigGroup = ref(false);

    const changeToOnePersone = () => {
      isOnePerson.value = true;
      isSmallGroup.value = false;
      isMiddleGroup.value = false;
      isBigGroup.value = false;
    };
    const changeToSmallGroup = () => {
      isOnePerson.value = false;
      isSmallGroup.value = true;
      isMiddleGroup.value = false;
      isBigGroup.value = false;
    };
    const changeToMiddleGroup = () => {
      isOnePerson.value = false;
      isSmallGroup.value = false;
      isMiddleGroup.value = true;
      isBigGroup.value = false;
    };
    const changeToBigGroup = () => {
      isOnePerson.value = false;
      isSmallGroup.value = false;
      isMiddleGroup.value = false;
      isBigGroup.value = true;
    };

    return {
      changeToBigGroup,
      changeToMiddleGroup,
      changeToSmallGroup,
      changeToOnePersone,
      isOnePerson,
      isSmallGroup,
      isMiddleGroup,
      isBigGroup,
    };
  },
});
