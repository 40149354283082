import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-100" }
const _hoisted_2 = { class: "pb-8 pb-lg-10" }
const _hoisted_3 = { class: "fw-bolder text-dark" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElNotification = _resolveComponent("ElNotification")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.resultSubscribeUser.hasOwnProperty('token'))
      ? (_openBlock(), _createBlock(_component_ElNotification, {
          key: 0,
          title: "Success",
          message: _ctx.resultSubscribeUser.message,
          type: "success",
          duration: 2000
        }, null, 8, ["message"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t("yourAreDone")) + "!", 1)
    ])
  ]))
}