
import { defineComponent, ref, computed } from "vue";
import { Field, ErrorMessage } from "vee-validate";

import { useStore } from "vuex";
export default defineComponent({
  name: "step-1",
  components: {
    Field,
    ErrorMessage,
  },
  setup() {
    const isGoogleRegister = ref(false);
    const store = useStore();

    const resultSubscribeUser = computed(() => {
      return store.getters.resultSubscribeUser;
    });

    const signUpWithGoogle = () => {
      isGoogleRegister.value = !isGoogleRegister.value;
    };

    return {
      isGoogleRegister,
      signUpWithGoogle,
      resultSubscribeUser,
    };
  },
});
