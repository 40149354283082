
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { ElNotification } from "element-plus";
export default defineComponent({
  name: "step-5",
  components: { ElNotification },
  setup() {
    const store = useStore();

    const resultSubscribeUser = computed(() => {
      return store.getters.resultSubscribeUser;
    });

    return {
      resultSubscribeUser,
    };
  },
});
